<template>
  <WidgetFrame>
    <template v-slot:title>
      Active Projects
    </template>
    <template v-slot:content>
      <ActiveProjectList class="project-list" :refresh="refreshList" @editProject="editProject" @refreshed="refreshed" />
    </template>
  </WidgetFrame>
</template>
<script>
  import { formatDate } from '@/helpers';

  export default {
    name: 'HomeActiveProjectsWidget',
    components: {
      ActiveProjectList: () => import('@/components/Dashboard/Widget/List/ActiveProjectList.vue'),
      WidgetFrame: () => import('@/components/Dashboard/WidgetFrame'),
    },
    props: {
      refresh:    { type: Boolean, default: false }
    },
    watch: {
      refresh(newValue) {
        if (newValue) {
          this.refreshList = newValue;
          this.$emit('refreshed');
        }
      }
    },
    data() {
      return {
        refreshList: false
      };
    },
    created() {
    },
    methods: {
      editProject(projectId) {
        this.$emit('editProject', projectId);
      },
      refreshed() {
        this.refreshList = false;
        this.$emit('refreshed');
      }
    }
  }
</script>

<style scoped>
  .project-list {
    flex-grow: 1;
  }
</style>